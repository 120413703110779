.text-dark-accent {
  color: #7D75E4;
}

.bg-dark-accent {
  background-color: #7D75E4;
}

@media all and (display-mode: standalone) {
  body, header, footer {
    padding-left: min(0vmin, env(safe-area-inset-left));
    padding-right: min(0vmin, env(safe-area-inset-right));
    padding-top: 20px;
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
  }
}
@font-face {
  font-family: customfont;
  src: url("./fonts/AgentSans-Regular.otf");
}
@font-face {
  font-family: customfontLight;
  src: url("./fonts/AgentSans-Light.otf");
}
.priceworx-font {
  font-family: "customfont";
  text-transform: uppercase;
  letter-spacing: 3px;
  background: #5d5d5d;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.priceworx-font-logo {
  font-family: "customfontLight";
  text-transform: uppercase;
  letter-spacing: 3px;
  background: #58595B;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.priceworx-font-logo-dark {
  font-family: "customfont";
  text-transform: uppercase;
  letter-spacing: 3px;
  background: #414042;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.priceworx-font-regular {
  font-family: "customfont";
  text-transform: uppercase;
  letter-spacing: 3px;
  background: #d2d2d2;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.priceworx-font-regular-dark {
  font-family: "customfont";
  text-transform: uppercase;
  letter-spacing: 3px;
  background: #373536;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.priceworx-font-light {
  font-family: "customfontLight";
  text-transform: uppercase;
  letter-spacing: 3px;
  background: #373536;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.priceworx-font-lightdark {
  font-family: "customfontLight";
  text-transform: uppercase;
  letter-spacing: 3px;
  background: #373536;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.priceworx-title {
  font-family: "customfont";
  letter-spacing: 3px;
  background: #5d5d5d;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.priceworx-gradient {
  background: linear-gradient(93deg, #DFF5F4 0%, #E2E1F9 100%);
}